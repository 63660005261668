@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100,0,300,0,400;1,300&display=swap');

:root{
    --white: rgb(255, 255, 255);
    --red : rgb(230, 46, 4) ;
    --light-red : rgb(255, 240, 235) ;
    --orange : rgb(255, 158, 39) ;
    --border-radius : 5px ;
    --main-transition : .3s ;
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* body{
    font-family: 'Poppins', sans-serif;
    font-weight: 100;
} */

body::-webkit-scrollbar{
    display: none;
}

/*  NAV BAR*/

nav {
    width: 100%;
    padding: .5em 0;
    background-color: var(--light-red);

    display: flex;
    justify-content: center;
    align-items: flex-end;
    column-gap: .8em;

    position: fixed;
    top: 0;
    z-index: 5;
}
@media (min-width:768px){
    nav{
        padding:1em 2em;
    }
}


.simpli_shop_app{
    width: 100%;
    margin: auto;
}

.simpli_shop_app .store_name_title {
    height: 30px;
}
@media (max-width:450px){
    .simpli_shop_app .store_name_lg{
        display: none;
    }
}
@media (min-width:450px) {
    .simpli_shop_app .store_name_md {
        display: none;
    }
}

.simpli_shop_app .search_bar{
    width: calc(100% - 300px);
    min-width: 150px;
    height: 40px;
    border: 2px solid var(--red);
    border-radius:  var(--border-radius);
}
@media (max-width:768px){
    .simpli_shop_app .search_bar{
        height: 30px;
    }
}
.simpli_shop_app .search_bar>input{
    font-size: 1rem;
    width: calc(100% - 40px);
    height: 100%;
    padding: 0 .5em;
    float:left;
    border: none;
    background: transparent;
}
.simpli_shop_app .search_bar>input:focus{
    outline: none;
}

.simpli_shop_app .search_bar>span{
    width: 40px;
    height: 100%;
    color: var(--white);
    float: right;
    background: var(--red);

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
}

nav .cart-icon{
    height: 100%;
    font-size: 1.7rem;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: flex-end;

    position: relative;
}
.cart_item_count{
    font-size: .8rem;
    position: absolute;
    top: -.5em;
    right: -.2em;

    background-color: var(--red);
    color: var(--white);
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    border-radius: 100%;
}
/* cart_side_bar */
.cart_side_bar{
    width:0%;
    max-width: 90%;
    height: 99vh;
    /* background-color: var(--light-red) ; */
    background-color: #fff;
    box-shadow: 0 0 10px .3px rgba(0,0,0,0.5) ;

    position: fixed;
    right: 0;
    top: 0;
    z-index: 5;
    overflow: hidden;

    transition: .3s ;
    
}
@media  (min-width: 768px) {
    .cart_side_bar{
        max-width: 500px;
    }
}

.cart_side_bar_header_section{
    padding: 1em;
    border-bottom: thin solid #aaa;

    display: flex;
    justify-content: space-between;
    align-items: center;
}
.close_cart_sidebar{
    font-size: 1.5rem;
    border-bottom: 2px solid white;
}
.close_cart_sidebar:hover{
    color: var(--red);
    border-bottom: 2px solid var(--red);
}

.cart_side_bar_cart_items_sections{
    height: calc(100% - 230px);
    padding-top: 1em;
    overflow: scroll;
}
.cart_side_bar_cart_items_sections::-webkit-scrollbar{
    display: none;
}

.ssbci{
    height: 100px;
    padding: .5em 0;
    margin: 0 1em;
    border-bottom: thin solid #aaa;

    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: .2em;
}

.ssbci_item_img_desc{
    height: 100%;
    /* width: 50%; */
    margin-left: .5em;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1em;
}
.ssbci_img{
    height: 85%;
    padding: .2em;
    background-color: #ccc;

    display: flex;
    justify-content: center;
    align-items: center;
}
.ssbci_img img{
    height: 100%;
}

.ssbci_qty{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

}
.ssbci_qty input{
    width: 40px;
    height: 30px;
    text-align: center;
    border: thin solid #ccc;
    border-radius: var(--border-radius);
    padding: 0 .2em;
}
.ssbci_qty input:focus{
    outline: none;
}

.ssbci_remove_item{
    width: 22px;
    height: 22px;
    border: 2px solid gray;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
}

.cart_side_bar_bottom_element{
    position: absolute;
    bottom: 0;
    width: 100%;
    font-size: 1.05rem;
    font-weight: bold;
}
.cart_side_bar_bottom_element .subtotals{
    height: 50px;
    width: 100%;
    background: var(--white);
    padding: 0 1em;
    border-top: thin solid #ccc;
    border-bottom: thin solid #ccc;
    margin-bottom: 1em;

    display: flex;
    justify-content: space-between;
    align-items: center;
    
}

.cart_side_bar_bottom_element button{
    width: 100%;
    height: 50px;
    border: none;
    font-weight: bold;
    color: var(--white);
    border-radius: var(--border-radius);
    background-color: var(--red) ;
    margin: 0 0 .5em;
    cursor: pointer;

}

@media (max-width:768px){
    .cart_side_bar_bottom_element button{
        height: 30px;
    }
        .cart_side_bar_bottom_element .subtotals{
            height: 50px;
        }
}



/* .user_icon */
nav .user_icon{
    font-size: .8rem;
    color: #666;
    padding: 0 .5em;
    list-style: none;
    cursor: pointer;
    transition: var(--main-transition) linear;
    
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
} 

nav .user_icon .account_options{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: .2em;
}
nav .user_icon .account_dropdown{
    width: 150px;
    max-height: 0;
    background-color: var(--white);
    list-style: none;
    box-shadow: 0 0 5px .1px #ddd;
    overflow: hidden;
    transition: max-height var(--main-transition) linear;
    cursor: default;

    
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 2;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

nav .user_icon:hover .account_dropdown{
    max-height: 400px;
}

nav .user_icon .account_options:hover .account_dropdown {
    max-height: 500px;

}

.user_icon .account_dropdown li{
    padding: 1em .5em;
    width: 100%;
}
.user_icon .account_dropdown .user_tag{
    font-weight: bold;
    border-bottom: 1px solid #ddd;
}
.user_icon .account_dropdown .register_tag{
    background-color: var(--red);
    color: white;
    transition: var(--main-transition);
    cursor: pointer;
}
.user_icon .account_dropdown .register_tag:hover{
    opacity: 80%;
}
/*  NAV BAR ENDS*/

/* banner */
.hero_section{
    width: 100%;
    min-height: calc(100vh - 60px);
    padding:0 0 2em ;
    background-color: var(--light-red);

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2em;
}
@media (max-width: 768px){
    .hero_section{
        flex-direction: column;
        gap: .5em;
    }
}

.hero_section .hero_img{
    width: 40%;
}
@media (max-width:768px) {
    .hero_section .hero_img {
        width: 100%;
    }
}
.hero_section .hero_img img{
    width: 100%;
    max-width: 700px;
}

.hero_section .hero_text{
    width: 40%;
    font-size: 1.1rem;
    letter-spacing: .05rem;
    text-align: center;
}
@media (max-width:768px) {
    .hero_section .hero_text {
        width: 100%;
    }
}


.hero_text .banner_title{
    font-size: 3rem;
    margin-bottom: .5em;
    
}
.banner_desc{
    margin-top: 1em;
    margin-bottom: 2em;
}
@media (max-width:768px){
    .hero_text .banner_title{
        font-size: 1.8rem;
    }
    .banner_desc{
        display: none;
    }
}

.hero_text .banner_btn{
    width: 150px;
    height: 45px;
    text-decoration: none !important;
    color: var(--white);
    background-color: var(--red);
    font-size: 1.2rem;
    padding: .5em;
    border: none;
    border-radius: var(--border-radius);
    cursor: pointer;
}
@media (min-width:768px) {
    .banner_btn {
        float: left;
    }
}

/* banner ends */

/* best selling product */
.home_page_bt{
    width: 100%;
    max-width: 992px;
    margin: auto;
}

.best_selling_products{
    margin-top: 2em;
    margin-bottom: 2em;
    padding: 0 .5em;
}
.best_selling_header{
    text-align: center;
    margin: 2em 0 2em;
}

.best_products_flex{
   display: grid;
   grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
   grid-column-gap: 1.5rem;
   grid-row-gap: 2rem;
}
@media (max-width:578px) {
    .best_products_flex {
        grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    }
}

.single_product{
    /* border: 2px solid rebeccapurple; */
    box-shadow: 0 0 10px .5px rgba(0,0,0,0.1);
    border-radius: var(--border-radius);
    padding: .5em;
    font-weight: 500;

    position: relative;
}
.over_lay{
    position: absolute;
    bottom: 0;
    z-index: 2;
    overflow: hidden;
    transition: 0.05s;
    border-radius: 5px;
    height: 0%;
    width: 100%;
    background-color: rgba(250, 46, 4, 0.5);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
}
.single_product:hover .over_lay{
    height: 100%;
}

.over_lay .view, .over_lay .to_cart{
    font-size: 1rem;
    text-align: center;
    width: 90%;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 1em 2em;
    cursor: pointer;
}
.over_lay .view:hover, .over_lay .to_cart:hover{
    background-color: rgba(255, 165, 0, 0.8);
}



.single_product .flex_image{
    width: 100%;
    /* max-height: 300px; */
    height: 50%;
    /* box-shadow: 0 0 3px .05px var(--red); */
    margin-bottom: .5em;
    border-radius: var(--border-radius);
    overflow: hidden;
}
.single_product .flex_image img{
    width: 100%;
}

.nrp{
    height: calc(50% - .5em);
    background-color: var(--light-red);
    /* border: 2px solid red; */
    color: #222;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.single_product .product_name{
    font-weight: bold;
    text-align: center;
    margin-top: 1em;

    width: 100%;
    height: 25px;
    text-align: center;
    overflow: hidden;
}

.product_price, .ratings{
    text-align: center;
}


/* why_buy_from_us */
.why_buy_from_us{
    width: 100%;
    padding: 0 .5em 2em;
    background-color: var(--light-red);
    margin: 2em 0 2em;
}

.why_buy_from_us> .why_header{
    text-align: center;
    padding: 1em 0;
    margin-bottom: 1em;
}

.why_buy_from_us .benefits{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    row-gap: .5em;
    column-gap: 1em;
}

.benefits .single_benefit{
    width: 24%;
    height: 150px;
    padding: 2em 0;
    padding-left: .5em;
    background-color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
    box-shadow: 0 0 5px .4px rgba(250, 46, 4, 0.5);
}
@media (max-width:768px) {
    .benefits .single_benefit {
        width: 98%;
    }
}
.single_benefit>p{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    row-gap: 1em;
}

.single_benefit .benefit_header{
    font-size: 1.2rem;
    font-weight: bold;
}

.customer_reviews{
    width: 100%;
    padding: 0 .5em;
    margin: auto;
    padding-top: 100px;
    text-align: center;
}

.row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: .5em;

    margin-top: 2em;
}

.review_col{
    width: 31%;
    border-radius: var(--border-radius);
    background-color: var(--light-red);
    margin-bottom: 5%;
    text-align: left;
    padding: 4em 1.5em;
    cursor: pointer;

    display: flex;
}
@media (max-width:992px) {
    .review_col {
        width: 49%;
    }
}
@media (max-width:578px) {
    .review_col {
        width: 100%;
    }
}

.review_col img{
    height: 40px;
    margin-left: 5px;
    margin-right: 30px;
    background-color: #666;
    border-radius: 50%;
}
.review_col>div>h3{
    margin-top: 1em;
}


.support_container{
    margin-bottom: 4em;
}
.support_container h1{
    text-align: center;
}
.support{
    margin: 2em 0 2em ;
    padding: 0 .5em;

    display: flex;
    justify-content: center;
    align-items: center;
    row-gap: 1em;
    column-gap:5em ;
}
.support>span{
    color: #444;
    cursor: pointer;
    transition: var(--main-transition);
}
.support>span:hover .icon{
    color: var(--red);
}
.support>span .icon{
    font-size: 3rem;
}
@media (max-width:768px){
    .support{
        column-gap: 1em;
    }
    .support>span .icon{
        font-size: 1.5rem !important;
    }
}


footer{
    font-size: 1rem;
    text-align: center;
    padding: .5em;
    color: var(--white);
    background: var(--red);
}

/* --------------------- PRODUCTS CATALOG PAGE ----------------------------- */
.product_catalog_container{
    width: 100vw;
    
    display: flex;
    justify-content: space-between;
    /* align-items: flex-start; */
}
@media (max-width:860px){
    .product_catalog_container{
        flex-direction: column;
    }
}

.filters_container{
    /* height: 200px; */
    padding: .5em;
    padding-top: 2em;
    background-color: rgba(0,0,0,0.7);
    color: #fff;
}
@media (min-width:860px){
    .filters_container{
        width: 20%;
        max-width: 280px;
        min-width: 200px;
        height: 50vh;

        position: sticky;
        top: 72px;
        overflow: scroll;
    }

    .filters_container::-webkit-scrollbar{
        display: none;
    }
}

.filters_container .filter_header{
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 1em;
}

.filters_wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;
    gap: 1em;
}
@media (min-width:860px){
    .filters_wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            row-gap: 1em;
        }
}

.filter_by_price input{
    width: 40px;
    margin-left: .5em;
    border: 2px solid #ccc;
    height: 30px;
    text-align: center;
    border-radius: var(--border-radius);
}
.clear_filters_btn{
    font-size: 1rem;
    font-weight: 500;
    border-radius: 5px;
    border: none;
    margin-top: 1em;
    width: 100%;
    height: 40px;
    cursor: pointer;
}


.divider{
    border: 1px solid #ddd;
    margin: 0 .5em;
}

.products_container{
    height: 160vh;
    padding: .5em;
    /* border: 2px solid rebeccapurple; */
}
@media (min-width:860px){
    .products_container{
        width: 90%;
        min-width: calc(100% - 280px);
        max-width: calc(100% - 200px);
    }
}

.catalog_header{
    margin-bottom: 1em;
    padding: .5em 3em;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.catalog_header h1{
    font-size:  3rem;
    font-weight: 500;
    color: var(--red);
}
@media (max-width:576px){
    .catalog_header h1{
        font-size: 1.5rem;
        padding: .5em 1em;
    }
}
.catalog_header .drop_down_category{
    font-size: 1rem;
    font-weight: 500;
    position: relative;
    padding: 0 .5em .5em;

    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.drop_down_category ul{
    list-style: none;
    display: block;
    max-height: 0;
    position: absolute;
    top: 105%;
    right: 0;
    z-index: 3;
    overflow: hidden;
    width: 150%;
    background-color: #fff;
    box-shadow: 0 0 10px .2px rgba(0,0,0,0.3);
    transition: max-height var(--main-transition) linear;
}
.drop_down_category ul li{
    padding: .5em .8em .5em;
    cursor: pointer;
}
.drop_down_category ul li:hover{
    background-color: #ccc;
}

.drop_down_category:hover ul{
    max-height: 500px;
}


.all_products{
    border: 2px solid magenta;
}
/* --------------------- PRODUCTS CATALOG PAGE ENDS ----------------------------- */

/* -----------------SINGLE PRODUCT------------------------- */
.single_item{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;
    gap: 1em;

    border: 2px solid gray;
}
@media (max-width:860px){
    .single_item{
        flex-direction: column;
    }
}
.single_item .single_item_img{
    width: 40%;
    height: 100px;
    border: 2px solid magenta;
}

.single_item .single_item_desc{
    padding: .5em;
    min-width: 250px;
    border: 2px solid red;

}
/* -----------------SINGLE PRODUCT ENDS------------------------- */